import React from "react";

export const SayHello = (): React.ReactElement => {
  return (
    <section className="section sayHello my-5 py-5">
      <h2 className="sayHello__tagline text-uppercase h6 font-weight-normal mb-4">
        SOCIAL URLS
      </h2>
      <div className="section__body sayHello__body">
        <ul className="list-unstyled">
          <li>
            <a
              href="mailto:arabindasaswat@gmail.com"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">Email</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/saswatarabinda/"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">linkedin</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/SaswatArabinda/"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">Facebook</p>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/ArabindaSaswat"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">Twitter</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/saswatarabinda/"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">Instagram</p>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SayHello;
