import React from "react";

interface IllustrationProps {
  title: string;
  shortDesc: string;
  biograpgy: string;
  name: string;
}

export const Welcome = ({
  title,
  shortDesc,
}: {
  title: string;
  shortDesc: string;
}): React.ReactElement => {
  return (
    <>
      <p className="illustration__left--title text-uppercase">{title}</p>
      <h1 className="illustration__left--heading display-1 font-weight-bold">
        {shortDesc}
      </h1>
    </>
  );
};

export const DisplayImage = (): React.ReactElement => {
  return (
    <section className="section displayImage">
      <div className="illustration__right__image" />
    </section>
  );
};

export const Illustration = (data: IllustrationProps): React.ReactElement => {
  const { title, shortDesc } = data;
  return (
    <section className="section illustration   min-vh-100 row">
      <div className="illustration__left col-12 col-xl-7 mt-lg-5 pt-lg-5">
        <Welcome title={title} shortDesc={shortDesc} />
      </div>
      <div className="illustration__right col-12 col-xl-5">
        <DisplayImage />
      </div>
    </section>
  );
};

export default Illustration;
