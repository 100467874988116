import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PortfolioHome } from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PortfolioHome />,
  },
]);

export const App = (): React.ReactElement => {
  return <RouterProvider router={router} />;
};

export default App;
