import React from "react";
import { Accordion } from "../accordion";

interface InternshipProps {
  title: string;
  institute: string;
  url: string;
  from: string;
  to: string;
  desc: string;
  guide: {
    name: string;
    address: string;
    url: string;
  };
}

const helper = (exp: InternshipProps, index: number): React.ReactElement => {
  const { title, institute, url, from, to, desc, guide } = exp;
  const props = {
    title,
    url,
    duration: `${from} - ${to}`,
    subTitle: institute,
    description: desc,
    key: `${title.replace(/ /g, "")}-${index}`,
    index,
    guide,
  };
  return <Accordion {...props} />;
};

export const Internship = ({
  data,
}: {
  data: InternshipProps[];
}): React.ReactElement => {
  return (
    <section className="section internship my-5 py-5">
      <h2 className="resume__tagline text-uppercase h6 font-weight-normal mb-4">
        Internship
      </h2>
      <div className="section__body resume__body">{data.map(helper)}</div>
    </section>
  );
};

export default Internship;
