import React from "react";
import { Accordion } from "../accordion";

interface ExperienceProps {
  companyName: string;
  url: string;
  position: string;
  location: string;
  from: string;
  to: string;
  desc: string;
  rolesAndResponsibility: string[];
  tools: string[];
}

const helper = (exp: ExperienceProps, index: number): React.ReactElement => {
  const {
    companyName: title,
    url,
    from,
    to,
    position,
    location,
    desc,
    rolesAndResponsibility,
    tools,
  } = exp;
  const props = {
    title,
    url,
    duration: `${from} - ${to}`,
    subTitle: position,
    description: desc,
    roles: rolesAndResponsibility,
    tools,
    location,
    key: `${title.replace(/ /g, "")}-${index}`,
    index,
  };
  return <Accordion {...props} />;
};

export const Experience = ({
  data,
}: {
  data: ExperienceProps[];
}): React.ReactElement => {
  return (
    <section className="section experience my-5 pt-5">
      <h2 className="resume__tagline text-uppercase h6 font-weight-normal mb-4">
        PROFESSIONAL EXPERIENCE
      </h2>
      <div className="section__body resume__body">{data.map(helper)}</div>
    </section>
  );
};

export default Experience;
