import React from "react";
import {
  // Illustration,
  About,
  Experience,
  Resume,
  SayHello,
  Projects,
  Internship,
  Publication,
  Welcome,
  DisplayImage,
} from "../../components";
import { data } from "../../data";

const COLORS = [
  "#ef5a20",
  "#232249",
  "#be073f",
  "#07376d",
  "#ce7e5a",
  // "#a691da",
  "#9a2d2d",
  "#2d7c9a",
  "#9a2d2d",
  "#196277",
  "#197766",
  "#193677",
  "#92175b",
  "#675398",
  "#69773f",
];

export const PortfolioHome = (): React.ReactElement => {
  const root = document.documentElement;
  const bgColor = COLORS[Math.floor(Math.random() * COLORS.length)];
  root.style.setProperty("--bg-main-color", bgColor);

  const {
    title,
    shortDesc,
    biograpgy,
    // name,
    // education,
    experience,
    internship,
    publication,
  } = data;
  // const illustrationProps = { title, shortDesc, biograpgy, name };
  const aboutProps = { biograpgy };
  const experienceProps = { data: experience };
  const internshipProps = { data: internship };
  const publicationProps = { data: publication };
  return (
    <div className="app container mt-5">
      <section className="section illustration mb-xl-5 pb-5  min-vh-100 row">
        <div className="illustration__left col-12 col-xl-7 mt-lg-5 pt-lg-5">
          <Welcome title={title} shortDesc={shortDesc} />
        </div>
        <div className="illustration__right col-12 col-xl-5">
          <DisplayImage />
        </div>
        <div>
          <About {...aboutProps} />
        </div>
      </section>

      {/* <Illustration {...illustrationProps} /> */}
      {/* <About {...aboutProps} /> */}
      <Experience {...experienceProps} />
      <Publication {...publicationProps} />
      <Internship {...internshipProps} />
      <Resume />
      <div className="row">
        <div className="col-3">
          <Projects />
        </div>
        <div className="col-3">
          <SayHello />
        </div>
      </div>

      <hr />

      <p className="text-end">THE END</p>

      {/* <p className="text-end">ctrl + c; ctrl + v; ctrl + s;</p> */}
    </div>
  );
};

export default PortfolioHome;
