import React from "react";

export const Resume = (): React.ReactElement => {
  return (
    <section className="section resume  my-5 py-5 text-uppercase text-center ">
      <a
        href="./resume.pdf"
        target="_blank"
        className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
      >
        <p className="">my resume</p>
      </a>
    </section>
  );
};

export default Resume;
