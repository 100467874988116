export const data = {
  title: "HELLO, WORLD!",
  shortDesc: "I'M A WEB DEVELOPER AND CODER.",
  biograpgy:
    "My name is Saswata Arabinda. Presently I am working as a Senior Associate Experience Technology, L2 for Publicis Sapient and I am responsible for developing component based applications. I have hands-on 4 years experience working on ReactJS HTML 5, CSS 3, JavaScript, and jQuery. My work includes designing responsive UI for entire application.",
  name: "Saswata Arabinda",
  address:
    "#A-312, Rajni Ashish Apartment, Thubarahalli, Bengaluru, Karnataka - 560066",
  phone: "9731884550",
  mail: "arabindasaswat@gmail.com",
  skills: {
    technologies: [
      "JavaScript",
      "Typescript",
      "ReactJS",
      "Apollo GraphQL",
      "Webpack",
      "Python",
      "HTML",
      "CSS",
      "C",
      "C++",
    ],
    testingLibraries: ["Jest", "Enzyme", "React Testing Library", "Jasmine"],
  },
  education: [
    {
      name: "B.Tech",
      institute: "KIIT University, Bhubaneswar.",
      specialization: "Infromation Technology",
      url: "https://kiit.ac.in/",
      from: "2009",
      to: "2013",
      result: "82.7%",
    },
    {
      name: "12th",
      institute: "Rajdhani Jr. College, Bhubaneswar.",
      specialization: "Science",
      url: "https://rajdhanicollege.org.in/",
      from: "2007",
      to: "2009",
      result: "68.0%",
    },
    {
      name: "10th",
      institute: "Saraswati Vidya Mandir, Bhubaneswar.",
      specialization: "",
      url: "http://ssvmu8bbsr.in/",
      from: "",
      to: "2007",
      result: "77.46%",
    },
  ],
  experience: [
    {
      companyName: "Publicis Sapient",
      url: "https://www.publicissapient.com/",
      position: "Senior Associate Experience Technology, L2",
      location: "Bengaluru, India",
      from: "October 2019",
      to: "Present",
      desc: "Working with Goldman Sachs Group as Tech Lead. Work involves developing multiple AYCO Advisor Desktop features which digitized client's financial data points and worksheets workflow to manage financial information efficiently. Responsible for requirement grooming with product team, architecture designs and base setup along with individual contribution.",
      rolesAndResponsibility: [
        "Designed and developed multiple model schemas, typedef, and APIs using Apollo GraphQL as Orchestration service to resolve Queries/Mutation for financial data points.",
        "Developed and operated a high-performance micro frontend architecture using Single-SPA framework and web components",
        "Application front-end was developed using REACT and Apollo Client framework and Apollo GraphQL as the service orchestration layer. Application build is configured via Gitlab pipelines and deployed in Amazon EC2 instance.",
      ],
      tools: [
        "ReactJS",
        "Typescript",
        "Single-SPA",
        "Apollo GraphQL",
        "Webpack",
        "GitHub",
      ],
    },
    {
      companyName: "Pegasystems",
      url: "https://www.pega.com/",
      position: "Software Engineer",
      location: "Bengaluru, India",
      from: "October 2016",
      to: "September 2019",
      desc: "Involved in the development and implementation of various UI components like Layout Group, Multiselect, autocomplete features, which are part of the UI Engineering Team for PRPC product (Version 8).",
      rolesAndResponsibility: [
        "Identified and reduced memory leaks related to old jQuery version and iframes for dynamic containers",
        "Worked on increasing browser rendering speed by reducing DOM depth and eliminating native APIs which are non-performant",
        "Directly interacted with multiple customers over call and provided resolutions for the escalated issues on time",
        "Certified System Architect and System Architect Essentials (PEGA CSA 7.2)",
      ],
      tools: [
        "HTML5",
        "Handlebars",
        "CSS 3",
        "JavaScript",
        "jQuery",
        "PRPC 8",
        "Karma",
      ],
    },
    {
      companyName: "Commonfloor / Quikr",
      url: "https://www.quikr.com/",
      position: "Software Development Engineer 1",
      location: "Bengaluru, India",
      from: "March 2015",
      to: "September 2016",
      desc: "Was part of the team that owns Quikr Doorstep, the customer-facing transport management system and developed productivity tools such as the Configuration Management System for internal use. Developed responsive web applications as well as mobile apps.",
      rolesAndResponsibility: [
        "Used ReactJS and jQuery to create a modular and reusable component for the user interface",
        "Integrated 3rd party technologies such as WebEngage, CrazyEgg and Google Analytics for enhancing the user experience",
      ],
      tools: [
        "HTML5",
        "CSS 3",
        "JavaScript",
        "jQuery",
        "ReactJS",
        "PHP",
        "GitHub",
      ],
    },
    {
      companyName: "MAQ Software",
      url: "https://maqsoftware.com/",
      position: "Software Engineer",
      location: "Hyderabad, India",
      from: "December 2013",
      to: "February 2015",
      desc: "Worked as a software developer on challenging projects which involved using Microsoft SharePoint and other related Microsoft products and working in cross-teams as well. The projects included developing web-based applications as well as mobile apps.",
      rolesAndResponsibility: [
        "Designing and Developing Responsive user interface using Bootstrap with technologies such as HTML5, CSS3, and JavaScript",
        "Implemented server-side security for preventing cross-site scripting (XSS)",
      ],
      tools: [
        "Microsoft Visual Studio 2013",
        "ASP.Net 4.5",
        "HTML5",
        "CSS 3",
        "JavaScript",
        "jQuery",
        "SharePoint 2013",
        "TFS",
        "CodeLook",
      ],
    },
  ],
  publication: [
    {
      title:
        "Test Case Generation for a Concurrent System using UML Activity Diagram",
      conference: "Region 10 Conference (TENCON), 2016 IEEE, Singapore",
      url: "http://ieeexplore.ieee.org/document/7848035/",
      from: "22 November 2016",
      to: "25 November 2016",
      desc: "This project involved presenting an approach of generating test cases for concurrent systems using the UML Activity diagram. In this work, we have presented an approach for generating a minimum test suite with maximum coverage.",
      guide: {
        name: "Prof. Arup Abhinna Acharya",
        address:
          "School of Computer Engineering, KIIT University, Bhubaneswar, India",
        url: "https://cse.kiit.ac.in/profiles/arup-abhinna-acharya/",
      },
    },
  ],
  internship: [
    {
      title: "Vehicle routing problem",
      institute: "IIT Kharagpur, India",
      location: "Kharagpur, India",
      url: "http://www.iitkgp.ac.in/",
      from: "May 2012",
      to: "July 2012",
      desc: "The vehicle routing problem (VRP) is a combinatorial optimization and integer programming problem seeking to service a number of customers with a fleet of vehicles, such that it has minimum total travel cost and maximum customer service in.",
      guide: {
        name: "Prof.Mamata Jenamani",
        address:
          "Department of Industrial Engineering & Management, IIT Kharagpur",
        url: "https://www.linkedin.com/in/mamata-jenamani-6a93b894/",
      },
    },
  ],
  socialMedia: [
    {
      title: "email",
      url: "mailto:arabindasaswat@gmail.com",
    },
    {
      title: "linkedin",
      url: "https://www.linkedin.com/in/saswatarabinda/",
    },
    {
      title: "facebook",
      url: "https://www.facebook.com/SaswatArabinda/",
    },
    {
      title: "twitter",
      url: "https://twitter.com/ArabindaSaswat",
    },
    {
      title: "instagram",
      url: "https://www.instagram.com/saswatarabinda/",
    },
  ],
  workAndFun: [
    {
      title: "ARRANGE TILES",
      url: "http://saswatarabinda.github.io/tiles/index.html",
    },
    {
      title: "SIMPLE CANVAS GAME",
      url: "http://saswatarabinda.github.io/game/index.html",
    },
  ],
};

export default data;
