import React from "react";

interface AccordionProps {
  title: string;
  url?: string;
  duration?: string;
  subTitle?: string;
  location?: string;
  description?: string;
  roles?: string[];
  tools?: string[];
  guide?: {
    name: string;
    address: string;
    url: string;
  };
  index?: number;
}

export const Accordion = (data: AccordionProps): React.ReactElement => {
  const {
    title,
    // url,
    duration,
    subTitle,
    description,
    roles,
    tools,
    guide,
    index,
    // location,
  } = data;
  const key = `${title.replace(/ /g, "")}-${index}`;
  return (
    <>
      <div className="accordion">
        <input
          id={key}
          type="radio"
          className="accordion-toggle"
          name="toggle"
        />
        <label htmlFor={key}>
          <div className="row">
            <h2 className="display-3 col-8 text-lowercase">{title}</h2>
            <div className="col-4 text-uppercase text-end align-self-center">
              <p className="">{duration}</p>
            </div>
          </div>
        </label>

        <section className="" style={{ fontWeight: 200 }}>
          <p className="h4 font-weight-normal" style={{ fontWeight: 200 }}>
            {subTitle} <br />
            <br />
            {description}
          </p>

          {roles ? (
            <span style={{ fontWeight: 200 }}>
              Roles and Responsibilities:
              <ul>
                {roles?.map((role, key) => {
                  return <li key={`${key}-${role}`}>{role}</li>;
                })}
              </ul>
            </span>
          ) : (
            ""
          )}

          {tools ? (
            <p>
              Environment and Tools:{" "}
              {tools.map((tool, key) => (
                <span className="accordion__tags" key={`${key}-${tool}`}>
                  {tool}
                </span>
              ))}
            </p>
          ) : (
            ""
          )}
          {guide ? (
            <p>
              Guide:{" "}
              <a
                href={guide.url}
                className="accordion__guide--name guidetext-uppercase h6 font-weight-normal mb-4 text-white"
              >
                <span>{guide.name}</span>
              </a>
              , <span>{guide.address}</span>
            </p>
          ) : (
            ""
          )}
        </section>
      </div>
    </>
  );
};

export default Accordion;
