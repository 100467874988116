import React from "react";

interface AboutProps {
  biograpgy: string;
}

export const About = (data: AboutProps): React.ReactElement => {
  const { biograpgy } = data;
  return (
    <section className="section about my-5  col-12 col-xl-6">
      <h2 className="section__tagline about__tagline text-uppercase h6 font-weight-normal mb-4">
        My Biography
      </h2>
      <p
        className="section__body h3 font-weight-normal"
        style={{ fontWeight: 200 }}
      >
        {biograpgy}
      </p>
    </section>
  );
};

export default About;
