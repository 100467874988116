import React from "react";
import { Accordion } from "../accordion";

interface PublicationProps {
  title: string;
  conference: string;
  url: string;
  from: string;
  to: string;
  desc: string;
  guide: {
    name: string;
    address: string;
    url: string;
  };
}

const helper = (exp: PublicationProps, index: number): React.ReactElement => {
  const { title, conference, url, from, to, desc, guide } = exp;
  const props = {
    title,
    url,
    duration: `${from} - ${to}`,
    subTitle: conference,
    description: desc,
    key: `${title.replace(/ /g, "")}-${index}`,
    index,
    guide,
  };
  return <Accordion {...props} />;
};

export const Publication = ({
  data,
}: {
  data: PublicationProps[];
}): React.ReactElement => {
  return (
    <section className="section publication my-5 pt-5">
      <h2 className="resume__tagline text-uppercase h6 font-weight-normal mb-4">
        Publication
      </h2>
      <div className="section__body resume__body">{data.map(helper)}</div>
    </section>
  );
};

export default Publication;
