import React from "react";

export const Projects = (): React.ReactElement => {
  return (
    <section className="section sayHello my-5 py-5">
      <h2 className="sayHello__tagline text-uppercase h6 font-weight-normal mb-4">
        work + fun
      </h2>
      <div className="section__body sayHello__body">
        <ul className="list-unstyled">
          <li>
            <a
              href="https://chat.saswatarabinda.com/"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">chat room</p>
            </a>
          </li>
          <li>
            <a
              href="http://saswatarabinda.github.io/tiles/index.html"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">arrange tiles</p>
            </a>
          </li>
          <li>
            <a
              href="http://saswatarabinda.github.io/game/index.html"
              className="resume__tagline text-uppercase h6 font-weight-normal mb-4 text-white"
            >
              <p className="h3">Simple canvas game</p>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Projects;
